import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { provider: String, submitBtnId: String }

  // eslint-disable-next-line complexity
  submit(event) {
    this.disableSubmit()
    if (this.weHaveAToken) {
      return true
    }

    event.preventDefault()

    this.formatPhoneNumber()
    this.handleSubmit()
  }

  //
  // private
  //

  handleSubmit() {
    this.setPaymentToken(this.sourceIsToken, this.sourceElement)
  }

  formatPhoneNumber() {
    const ownerPhone = document.querySelector('#owner-phone')
    const ownerFullPhone = document.querySelector('input[name="owner-full-phone"]')
    const ownerTelInput = window.intlTelInputGlobals.getInstance(ownerPhone)
    ownerFullPhone.value = ownerTelInput.getNumber()

    const adminPhone = document.querySelector('#admin-phone')
    const adminFullPhone = document.querySelector('input[name="admin-full-phone"]')
    const adminTelInput = window.intlTelInputGlobals.getInstance(adminPhone)
    adminFullPhone.value = adminTelInput.getNumber()
  }

  setPaymentToken(sourceIsToken, sourceElement) {
    this.billingProviderController.setPaymentToken(sourceIsToken, sourceElement).then((success) => {
      if (success) {
        // Need to find the form, horray....
        const form = document.querySelector('form#new-account-form')
        form.requestSubmit()
      } else {
        // eslint-disable-next-line no-console
        console.log('tokenizer: tokenization failed')
        this.enableSubmit()
      }
    })
  }

  disableSubmit() {
    this.element.disabled = true
  }

  enableSubmit() {
    this.element.disabled = false
  }

  get sourceTypeElement() {
    return document.querySelector('#vault_payment_information_source_type')
  }

  get sourceElement() {
    return document.querySelector('#vault_payment_information_source')
  }

  get billingProviderController() {
    let controllerName = this.providerValue
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase()
    return this.application.getControllerForElementAndIdentifier(this.element, controllerName)
  }

  get sourceIsToken() {
    return this.sourceTypeElement.value === 'token'
  }

  get weHaveAToken() {
    return this.sourceIsToken && this.sourceElement.value
  }
}
