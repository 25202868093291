import jstz from 'jstz'

// Rewrite this functionality in app/javascript/controllers/accounts_controller.js when we redo the open page stuff
$(document).on('turbo:load', function () {
  'use strict'

  // Handle grabbing the user's timezone and adding to a hidden form field
  if ($("[name='timezone']").length) $("[name='timezone']").val(jstz.determine().name())

  // Handle allowing only lowercase alphanumeric characters for subdomain
  $("[name='subdomain']").keypress(function (e) {
    const regex = /^([a-z]|[0-9])+$/
    const str = String.fromCharCode(e.charCode ? e.charCode : e.which)
    const validCharacter = e.charCode === 0 || regex.test(str)
    if (!validCharacter) e.preventDefault()
    return validCharacter
  })

  function adminIsOwner() {
    return document.getElementById('admin-same-as-owner').checked
  }

  function capitalize(input) {
    return input.charAt(0).toUpperCase() + input.slice(1)
  }

  function luhn(num) {
    const number = num.replace(/\D/g, '')
    const numArray = number
      .split('')
      .reverse()
      .map((n) => parseInt(n))
    const checksum = numArray.shift()

    const sum = numArray.reduce((acc, n, i) => {
      if (i % 2 === 0) {
        n *= 2
        if (n > 9) n -= 9
      }
      return acc + n
    }, checksum)

    return sum % 10 === 0
  }

  function creditCardTypeFromNumber(num) {
    const quickCards = {
      master: /^5[1-5]/,
      visa: /^4/,
      american_express: /^3[47]/,
      discover: /^(6011|65|64[4-9]|622)/,
    }

    const fullCards = {
      master:
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
      visa: /^4[0-9]{6,}$/,
      american_express: /^3[47][0-9]{5,}$/,
      discover: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
    }

    let parsedNum = num.replace(/[^\d]/g, '')

    const quickType = Object.keys(quickCards).find((type) => quickCards[type].test(parsedNum))
    const fullType = Object.keys(fullCards).find((type) => fullCards[type].test(parsedNum))

    return [quickType, fullType]
  }

  function camelCase(input) {
    return input.toLowerCase().replace(/-(.)/g, function (match, group1) {
      return group1.toUpperCase()
    })
  }

  function isFormFieldValidationImmediate(field) {
    const customvalidationMethod = `is${capitalize(camelCase(field.attr('name')))}FormFieldValidationImmediate`
    if (typeof window[customvalidationMethod] === 'function') return window[customvalidationMethod](field)
    return true
  }

  function isFormFieldValid(field) {
    const customvalidationMethod = `is${capitalize(camelCase(field.attr('name')))}FormFieldValid`
    if (typeof window[customvalidationMethod] === 'function') return window[customvalidationMethod](field)
    if (field.attr('name') === 'admin-phone' && adminIsOwner()) return true
    if (field.attr('name') === 'vault[payment_information][billing_address][address2]') return true
    return $.trim(field.val()) !== ''
  }

  function markActiveFormSectionIncomplete() {
    $('#footer .btn-next').attr('disabled', true)
    $('#footer .btn-submit').attr('disabled', true)
  }

  function markActiveFormSectionComplete() {
    $('#footer .content span.active span.full-text .form-section-complete-icon').removeClass('hidden')
    $('#footer .btn-next').attr('disabled', false)
    $('#footer .btn-submit:visible').attr('disabled', false)
  }

  function isActiveFormSectionValid() {
    let isValid = true
    $('.form-puzzle-piece.active')
      .find('input:not(.hidden), select')
      .each(function () {
        if (!isFormFieldValid($(this))) {
          isValid = false
          return false
        }
        return true
      })
    return isValid
  }

  $('#new-account-form input, #new-account-form select').on('focusout', function () {
    if ($(this).val() && isFormFieldValidationImmediate($(this)) && !isFormFieldValid($(this))) {
      $(this).addClass('input-danger')
    }
  })

  // Handle allowing user to move to next section, assuming current section is all valid
  $('#new-account-form input').on('input', function () {
    if (isFormFieldValidationImmediate($(this)) && isFormFieldValid($(this))) $(this).removeClass('input-danger')

    if (isActiveFormSectionValid()) return markActiveFormSectionComplete()
    markActiveFormSectionIncomplete()
    return $('#footer .content span.active span.full-text .form-section-complete-icon').addClass('hidden')
  })

  // Handle allowing user to move to next section, assuming current section is all valid
  $('#new-account-form select').change(function () {
    if (isFormFieldValidationImmediate($(this)) && isFormFieldValid($(this))) $(this).removeClass('input-danger')

    if (isActiveFormSectionValid()) return markActiveFormSectionComplete()
    markActiveFormSectionIncomplete()
    return $('#footer .content span.active span.full-text .form-section-complete-icon').addClass('hidden')
  })

  function focusFirstFormField(container) {
    return container.find('input').first().focus()
  }

  // Handle going to the next section of the form
  $('#footer .btn-next').click(function () {
    const puzzle = $('.form-puzzle')
    const activePiece = puzzle.find('.form-puzzle-piece.active')
    const nextPiece = activePiece.next()
    markActiveFormSectionIncomplete()
    activePiece.fadeOut('fast', function () {
      activePiece.removeClass('active')
      return nextPiece.fadeIn('slow', function () {
        nextPiece.addClass('active')
        focusFirstFormField(nextPiece)
        if (isActiveFormSectionValid()) return markActiveFormSectionComplete()

        return true
      })
    })
    const backButton = $('#footer .btn-back')
    backButton.attr('disabled', false)
    const footerContent = $('#footer .content')
    const activeFooterItem = footerContent.find('span.active')
    activeFooterItem.removeClass('active')
    const nextFooterItem = activeFooterItem.next().next()
    nextFooterItem.addClass('active')
    if (!nextPiece.next().hasClass('form-puzzle-piece')) {
      $(this).attr('disabled', true)
      $(this).hide()
      const submitButton = $('#footer .btn-submit')
      submitButton.show()
    }
  })

  // Handle going back to the previous form section
  $('#footer .btn-back').click(function () {
    const puzzle = $('.form-puzzle')
    const activePiece = puzzle.find('.form-puzzle-piece.active')
    const prevPiece = activePiece.prev()
    activePiece.fadeOut('fast', function () {
      activePiece.removeClass('active')
      return prevPiece.fadeIn('slow', function () {
        prevPiece.addClass('active')
        return focusFirstFormField(prevPiece)
      })
    })
    const footerContent = $('#footer .content')
    const activeFooterItem = footerContent.find('span.active')
    activeFooterItem.removeClass('active')
    const prevFooterItem = activeFooterItem.prev().prev()
    prevFooterItem.addClass('active')
    const submitButton = $('#footer .btn-submit')
    submitButton.hide()
    const nextButton = $('#footer .btn-next')
    nextButton.attr('disabled', false)
    nextButton.show()
    if (!prevPiece.prev().hasClass('form-puzzle-piece')) {
      $(this).attr('disabled', true)
      nextButton.attr('disabled', false)
    }
  })

  window.isOwnerNameFormFieldValid = function (field) {
    return $.trim(field.val()).length >= 2
  }

  window.isAdminNameFormFieldValid = function (field) {
    if (adminIsOwner()) return true
    return $.trim(field.val()).length >= 2
  }

  window.isOwnerFullPhoneFormFieldValid = function () {
    return true
  }

  window.isAdminFullPhoneFormFieldValid = function () {
    return true
  }

  window.isSchoolNameFormFieldValid = function (field) {
    return $.trim(field.val()).length >= 2
  }

  window.isOwnerEmailFormFieldValid = function (field) {
    const regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    return regex.test(field.val())
  }

  window.isAdminEmailFormFieldValid = function (field) {
    if (adminIsOwner()) return true
    const regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    return regex.test(field.val())
  }

  var accountCreationSubdomainCheck = null
  window.isSubdomainFormFieldValid = function (field) {
    const regex = /^([a-z]|[0-9]){2,}$/
    const parent = field.closest('.subdomain-fields')
    if (regex.test(field.val())) {
      parent.find('.form-field-start').addClass('hidden')
      parent.find('.spinner').removeClass('hidden')
      const before = field.val()
      setTimeout(function () {
        if (before === field.val()) {
          if (accountCreationSubdomainCheck) accountCreationSubdomainCheck.abort()

          accountCreationSubdomainCheck = $.ajax({
            url: field.data('path'),
            type: 'GET',
            data: {
              subdomain: field.val(),
              strict: true,
            },
            dataType: 'json',
            success(data) {
              parent.find('.form-field-start').addClass('hidden')
              if (data.length > 0) {
                parent.find('.not-available').removeClass('hidden')
                $(field).addClass('input-danger')
                markActiveFormSectionIncomplete()
                $('#footer .content span.active span.full-text .form-section-complete-icon').addClass('hidden')
              } else {
                parent.find('.available').removeClass('hidden')
                $(field).removeClass('input-danger')
                markActiveFormSectionComplete()
              }
            },
            error() {
              parent.find('.form-field-start').addClass('hidden')
              parent.find('.server-error').removeClass('hidden')
              markActiveFormSectionIncomplete()
              $('#footer .content span.active span.full-text .form-section-complete-icon').addClass('hidden')
            },
          })
          return accountCreationSubdomainCheck
        }

        return true
      }),
        500
    } else {
      parent.find('.form-field-start').addClass('hidden')
    }

    // Note: The asynchronous call to check the backend will determine this field's validity
    return false
  }

  window.isUsernameFormFieldValid = function (field) {
    return $.trim(field.val()).length >= 4
  }

  window.isBillingNameFormFieldValid = function (field) {
    const regex = /^[A-z]{2,} .*[A-z]{2,}$/
    return regex.test(field.val())
  }

  window.isBillingNumberFormFieldValid = function (field) {
    const [quickType, fullType] = creditCardTypeFromNumber(field.val())

    if (quickType) {
      $('.credit-card-type').addClass('hidden')
      $(`.credit-card-type.${quickType}`).removeClass('hidden')
    } else {
      $('.credit-card-type').addClass('hidden')
    }

    if (fullType) {
      $("[name='billing-credit-card-type']").val(fullType)
    } else {
      $("[name='billing-credit-card-type']").val('')
      return false
    }

    if (!luhn(field.val())) return false

    return true
  }

  window.isPasswordFormFieldValid = function (field) {
    if ($.trim(field.val()).length < 4) return false

    return $("[name='password-confirmation']").val() === field.val()
  }

  window.isPasswordConfirmationFormFieldValid = function (field) {
    if ($.trim(field.val()).length < 4) return false

    return $("[name='password']").val() === field.val()
  }

  window.isSubdomainFormFieldValidationImmediate = function () {
    return false
  }

  window.isPasswordFormFieldValidationImmediate = function () {
    return false
  }

  // Handles marking the first section complete if already filled out after form submission errors
  if (isActiveFormSectionValid()) return markActiveFormSectionComplete()

  return true
})
